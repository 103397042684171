body .ant-pro-layout {
  height: 100%;
  column-gap: 30px;
  padding: 0 50px 30px;
}
body .ant-pro-layout .ant-pro-layout-bg-list {
  background-size: cover;
  background-repeat: no-repeat !important;
  background-image: url('../../assets/bg-layout-header.png') !important;
}
body .ant-pro-layout .ant-layout {
  column-gap: 30px;
}
body .ant-pro-layout .ant-layout-header {
  background-size: cover;
  border-block-end: none !important;
  background-image: url('../../assets/bg-layout-header.png');
}
body .ant-pro-layout .ant-layout-header .ant-pro-top-nav-header-main {
  padding-left: 50px;
}
body .ant-pro-layout .ant-layout-header .ant-pro-top-nav-header-logo > *:first-child > h1 {
  color: #fff;
  margin-left: 12px;
  font-family: FZZhengHeiS-DB-GB;
}
body .ant-pro-layout .ant-layout-header .ant-pro-global-header-header-actions-avatar {
  padding-right: 50px;
}
body .ant-pro-layout .ant-layout-header .ant-pro-global-header-header-actions-item .anticon {
  opacity: 0.8;
  color: #fff;
  font-size: 20px;
}
body .ant-pro-layout .ant-layout-header .ant-pro-global-header-header-actions-item .anticon:hover {
  opacity: 1;
}
body .ant-pro-layout .ant-layout-header .ant-pro-top-nav-header-menu {
  padding-left: 5%;
  padding-right: 5%;
}
body .ant-pro-layout .ant-layout-header .ant-pro-top-nav-header-menu .ant-pro-base-menu-horizontal-item-text {
  font-size: 16px;
}
body .ant-pro-layout .ant-layout-header .ant-menu-item,
body .ant-pro-layout .ant-layout-header .ant-menu-submenu {
  color: #ffffff !important;
  opacity: 0.8 !important;
}
body .ant-pro-layout .ant-layout-header .ant-menu-item:hover,
body .ant-pro-layout .ant-layout-header .ant-menu-submenu:hover {
  opacity: 1 !important;
}
body .ant-pro-layout .ant-layout-header .ant-menu-item-selected {
  opacity: 1 !important;
}
body .ant-pro-layout .ant-layout-header .ant-menu-item-selected:after {
  height: 12px;
  bottom: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/header-menu-active.png');
}
body .ant-pro-layout .ant-layout-header .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #ffffff !important;
  opacity: 1 !important;
}
body .ant-pro-layout .ant-pro-sider.ant-pro-sider-fixed {
  left: auto !important;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children {
  border-radius: 20px;
  padding: 0 !important;
  backdrop-filter: blur(83px);
  border-right: none !important;
  background-color: rgba(255, 255, 255, 0.5);
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu-root {
  padding: 10px !important;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu {
  border: none;
  color: #5c6269;
  background: transparent;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-pro-base-menu-inline-item-icon {
  color: var(--ant-color-primary);
  padding: 3px 0;
  height: 40px;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-pro-base-menu-inline-item-icon .anticon {
  font-size: 24px !important;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed {
  width: 40px;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 8px;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 8px;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-title {
  border-radius: 6px;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
  border-radius: 6px;
  font-size: 14px;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-item::after {
  display: none;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected {
  color: #fff !important;
  font-weight: 500;
  background-color: var(--ant-color-primary) !important;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-item-selected .anticon {
  color: #fff;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #fff;
  font-weight: 500;
  background-color: var(--ant-color-primary);
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title .anticon {
  color: #fff;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow {
  color: #fff;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-selected .ant-menu-item-selected {
  background-color: #e6f5ff !important;
  color: var(--ant-color-primary) !important;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-submenu-selected .ant-menu-item-selected .ant-menu-title-content::before {
  background-color: var(--ant-color-primary);
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-sub {
  color: #2f343a;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-sub .ant-menu-title-content {
  display: flex;
  align-items: center;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu .ant-menu-sub .ant-menu-title-content::before {
  width: 5px;
  height: 5px;
  content: '';
  display: inline-block;
  border-radius: 100%;
  margin-right: 15px;
  vertical-align: middle;
}
body .ant-pro-layout .ant-pro-sider .ant-layout-sider-children .ant-menu-sub.ant-menu-inline {
  background: transparent;
}
body .ant-pro-layout .ant-pro-sider.ant-pro-sider-fixed-mix {
  height: calc(100% - 86px - 30px) !important;
  top: 86px !important;
}
body .ant-pro-layout .ant-pro-page-container-children-container {
  padding: 20px 30px !important;
}
body .ant-pro-layout .ant-pro-page-container-children-container .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding-top: 0 !important;
}
body .ant-pro-layout .ant-pro-layout-container .ant-pro-layout-content {
  padding: 30px;
  border-radius: 20px;
  backdrop-filter: blur(83px);
  background-color: rgba(255, 255, 255, 0.5);
}
body .ant-pro-layout .ant-pro-layout-container .ant-pro-layout-content .ant-pro-page-container {
  border-radius: 10px;
  background-color: var(--ant-color-white);
}
body .ant-divider .ant-divider-inner-text {
  display: flex !important;
  column-gap: 10px;
  font-weight: 500;
  padding-left: 0 !important;
}
body .ant-divider .ant-divider-inner-text .anticon {
  font-size: 22px;
  color: var(--ant-color-primary);
}
body .ant-pro-table .ant-pro-table-search {
  border-radius: 10px;
  margin-bottom: 20px !important;
  background: rgba(11, 37, 69, 0.02) !important;
}
body .ant-pro-table .ant-pro-table-list-toolbar-container {
  padding-top: 0 !important;
  padding-bottom: 20px;
}
body .ant-pro-table .ant-pro-card-body {
  padding: 0 !important;
}
body .ant-pro-table .ant-table-wrapper .ant-table {
  border-radius: 10px;
}
body .ant-pro-table .ant-table-wrapper .ant-table .ant-table-thead > tr > th {
  font-weight: normal;
}
body .ant-modal-root .ant-modal.rl-modal .ant-modal-content {
  padding: 0 !important;
  border-radius: 20px !important;
}
body .ant-modal-root .ant-modal.rl-modal .ant-modal-content .ant-modal-close {
  top: 28px;
}
body .ant-modal-root .ant-modal.rl-modal .ant-modal-content .ant-modal-header {
  margin: 0;
  padding: 24px !important;
  border-radius: 20px 20px 0 0 !important;
  background-color: #eaf1fb;
}
body .ant-modal-root .ant-modal.rl-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: 500;
  font-size: 20px;
}
body .ant-modal-root .ant-modal.rl-modal .ant-modal-content .rl-modal-body-content {
  padding: 24px;
}
body .ant-picker {
  width: 100%;
}
body .ant-tag {
  padding-left: calc(8px - var(--ant-line-width)) !important;
  padding-right: calc(8px - var(--ant-line-width)) !important;
}
body .ant-drawer .ant-drawer-content-wrapper {
  border-radius: 20px 0 0 20px;
}
body .ant-drawer .ant-drawer-content-wrapper .ant-drawer-content {
  border-radius: 20px 0 0 20px;
}
body .ant-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header {
  border-bottom: none !important;
  background: var(--ant-color-primary);
  padding: 20px var(--ant-padding-lg) !important;
}
body .ant-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header .ant-drawer-close {
  opacity: 0.8;
  color: #fff;
  font-size: 20px;
}
body .ant-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header .ant-drawer-close:hover {
  color: #fff;
  opacity: 1;
}
body .ant-drawer .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
body .rl-message-success {
  margin-top: calc(50vh - 130px);
}
body .rl-message-success .ant-message-notice-content {
  padding: 0 !important;
  border-radius: 10px;
  overflow: hidden;
}
body .rl-message-success .ant-message-notice-content .ant-message-custom-content {
  width: 250px;
  height: 130px;
  display: flex;
  flex-direction: column;
}
body .rl-message-success .ant-message-notice-content .ant-message-custom-content .anticon {
  top: 0;
  height: 90px;
  margin-right: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('../../assets/bg-message-success.jpg');
}
body .rl-message-success .ant-message-notice-content .ant-message-custom-content .anticon svg {
  display: none;
}
body .rl-message-success .ant-message-notice-content .ant-message-custom-content span:nth-of-type(2) {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #0173fc;
  line-height: 40px;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
body .rl-modal .ant-input-outlined.ant-input-disabled,
body .rl-drawer .ant-input-outlined.ant-input-disabled {
  border-color: transparent !important;
}
body .rl-modal .ant-input-outlined.ant-input-disabled input::placeholder,
body .rl-drawer .ant-input-outlined.ant-input-disabled input::placeholder {
  color: transparent;
}
body .rl-modal .ant-input-number-outlined.ant-input-number-disabled,
body .rl-drawer .ant-input-number-outlined.ant-input-number-disabled {
  border-color: transparent !important;
}
body .rl-modal .ant-select-outlined.ant-select-disabled .ant-select-selector,
body .rl-drawer .ant-select-outlined.ant-select-disabled .ant-select-selector {
  border-color: transparent !important;
}
body .rl-modal .ant-select-outlined.ant-select-disabled .ant-select-selection-placeholder,
body .rl-drawer .ant-select-outlined.ant-select-disabled .ant-select-selection-placeholder {
  display: none;
}
body .rl-modal .ant-picker-outlined.ant-picker-disabled,
body .rl-drawer .ant-picker-outlined.ant-picker-disabled {
  border-color: transparent !important;
}
body .rl-modal .ant-picker-outlined.ant-picker-disabled input::placeholder,
body .rl-drawer .ant-picker-outlined.ant-picker-disabled input::placeholder {
  color: transparent;
}
body .ant-radio-group-small .ant-radio-button-wrapper {
  padding-left: calc(var(--ant-padding-xs) - var(--ant-line-width)) !important;
  padding-right: calc(var(--ant-padding-xs) - var(--ant-line-width)) !important;
}
body .ant-image-preview-root .ant-image-preview-mask,
body .ant-image-preview-root .ant-image-preview-wrap {
  z-index: 1001;
}
body .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
body .ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none!important;
}
body .ant-select-selector {
  overflow: auto;
  max-height: 114px;
}
