/*@tailwind base;*/

.container {
  width: 100%
}

@media (min-width: 640px) {
  .container {
    max-width: 640px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px
  }
}

.visible {
  visibility: visible !important
}

.fixed {
  position: fixed !important
}

.absolute {
  position: absolute !important
}

.relative {
  position: relative !important
}

.sticky {
  position: sticky !important
}

.bottom-\[70px\] {
  bottom: 70px !important
}

.left-0 {
  left: 0px !important
}

.left-\[100px\] {
  left: 100px !important
}

.right-0 {
  right: 0px !important
}

.right-\[-21px\] {
  right: -21px !important
}

.top-0 {
  top: 0px !important
}

.top-\[-11\.5px\] {
  top: -11.5px !important
}

.top-\[-6px\] {
  top: -6px !important
}

.top-\[-8px\] {
  top: -8px !important
}

.top-\[116px\] {
  top: 116px !important
}

.top-\[370px\] {
  top: 370px !important
}

.top-\[52px\] {
  top: 52px !important
}

.z-0 {
  z-index: 0 !important
}

.z-10 {
  z-index: 10 !important
}

.z-\[888\] {
  z-index: 888 !important
}

.z-\[99\] {
  z-index: 99 !important
}

.m-auto {
  margin: auto !important
}

.mx-\[-10px\] {
  margin-left: -10px !important;
  margin-right: -10px !important
}

.mx-\[-12px\] {
  margin-left: -12px !important;
  margin-right: -12px !important
}

.mx-\[-24px\] {
  margin-left: -24px !important;
  margin-right: -24px !important
}

.mx-\[15px\] {
  margin-left: 15px !important;
  margin-right: 15px !important
}

.mx-\[7px\] {
  margin-left: 7px !important;
  margin-right: 7px !important
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important
}

.my-\[-16px\] {
  margin-top: -16px !important;
  margin-bottom: -16px !important
}

.my-\[-4px\] {
  margin-top: -4px !important;
  margin-bottom: -4px !important
}

.my-\[10px\] {
  margin-top: 10px !important;
  margin-bottom: 10px !important
}

.my-\[15px\] {
  margin-top: 15px !important;
  margin-bottom: 15px !important
}

.my-\[20px\] {
  margin-top: 20px !important;
  margin-bottom: 20px !important
}

.my-\[40px\] {
  margin-top: 40px !important;
  margin-bottom: 40px !important
}

.my-\[5px\] {
  margin-top: 5px !important;
  margin-bottom: 5px !important
}

.my-\[6px\] {
  margin-top: 6px !important;
  margin-bottom: 6px !important
}

.my-\[8px\] {
  margin-top: 8px !important;
  margin-bottom: 8px !important
}

.mb-\[-18px\] {
  margin-bottom: -18px !important
}

.mb-\[15px\] {
  margin-bottom: 15px !important
}

.mb-\[20px\] {
  margin-bottom: 20px !important
}

.mb-\[24px\] {
  margin-bottom: 24px !important
}

.mb-\[30px\] {
  margin-bottom: 30px !important
}

.ml-\[-15px\] {
  margin-left: -15px !important
}

.ml-\[10px\] {
  margin-left: 10px !important
}

.ml-\[15px\] {
  margin-left: 15px !important
}

.ml-\[20px\] {
  margin-left: 20px !important
}

.ml-\[47px\] {
  margin-left: 47px !important
}

.ml-\[8px\] {
  margin-left: 8px !important
}

.mr-\[12px\] {
  margin-right: 12px !important
}

.mr-\[5px\] {
  margin-right: 5px !important
}

.mt-\[-24px\] {
  margin-top: -24px !important
}

.mt-\[-30px\] {
  margin-top: -30px !important
}

.mt-\[10px\] {
  margin-top: 10px !important
}

.mt-\[15px\] {
  margin-top: 15px !important
}

.mt-\[20px\] {
  margin-top: 20px !important
}

.mt-\[4px\] {
  margin-top: 4px !important
}

.mt-\[6px\] {
  margin-top: 6px !important
}

.mt-\[8px\] {
  margin-top: 8px !important
}

.block {
  display: block !important
}

.inline-block {
  display: inline-block !important
}

.inline {
  display: inline !important
}

.flex {
  display: flex !important
}

.inline-flex {
  display: inline-flex !important
}

.table {
  display: table !important
}

.grid {
  display: grid !important
}

.inline-grid {
  display: inline-grid !important
}

.hidden {
  display: none !important
}

.h-\[120px\] {
  height: 120px !important
}

.h-\[140px\] {
  height: 140px !important
}

.h-\[18px\] {
  height: 18px !important
}

.h-\[196px\] {
  height: 196px !important
}

.h-\[1px\] {
  height: 1px !important
}

.h-\[216px\] {
  height: 216px !important
}

.h-\[22px\] {
  height: 22px !important
}

.h-\[238px\] {
  height: 238px !important
}

.h-\[250px\] {
  height: 250px !important
}

.h-\[300px\] {
  height: 300px !important
}

.h-\[320px\] {
  height: 320px !important
}

.h-\[36px\] {
  height: 36px !important
}

.h-\[38px\] {
  height: 38px !important
}

.h-\[400px\] {
  height: 400px !important
}

.h-\[40px\] {
  height: 40px !important
}

.h-\[412px\] {
  height: 412px !important
}

.h-\[420px\] {
  height: 420px !important
}

.h-\[45px\] {
  height: 45px !important
}

.h-\[50vh\] {
  height: 50vh !important
}

.h-\[550px\] {
  height: 550px !important
}

.h-\[570px\] {
  height: 570px !important
}

.h-\[5px\] {
  height: 5px !important
}

.h-\[64px\] {
  height: 64px !important
}

.h-\[70px\] {
  height: 70px !important
}

.h-\[80px\] {
  height: 80px !important
}

.h-\[85vh\] {
  height: 85vh !important
}

.h-\[90vh\] {
  height: 90vh !important
}

.h-\[calc\(100vh-166px\)\] {
  height: calc(100vh - 166px) !important
}

.h-\[calc\(100vh-206px\)\] {
  height: calc(100vh - 206px) !important
}

.h-\[calc\(100vh-277px\)\] {
  height: calc(100vh - 277px) !important
}

.h-\[calc\(100vh-318px\)\] {
  height: calc(100vh - 318px) !important
}

.h-\[calc\(100vh-482px\)\] {
  height: calc(100vh - 482px) !important
}

.h-full {
  height: 100% !important
}

.h-screen {
  height: 100vh !important
}

.max-h-\[40px\] {
  max-height: 40px !important
}

.min-h-0 {
  min-height: 0px !important
}

.min-h-\[360px\] {
  min-height: 360px !important
}

.min-h-\[45px\] {
  min-height: 45px !important
}

.min-h-full {
  min-height: 100% !important
}

.w-\[100\%\] {
  width: 100% !important
}

.w-\[112px\] {
  width: 112px !important
}

.w-\[120px\] {
  width: 120px !important
}

.w-\[140px\] {
  width: 140px !important
}

.w-\[172px\] {
  width: 172px !important
}

.w-\[180px\] {
  width: 180px !important
}

.w-\[18px\] {
  width: 18px !important
}

.w-\[210px\] {
  width: 210px !important
}

.w-\[21cm\] {
  width: 21cm !important
}

.w-\[22px\] {
  width: 22px !important
}

.w-\[300px\] {
  width: 300px !important
}

.w-\[30px\] {
  width: 30px !important
}

.w-\[40px\] {
  width: 40px !important
}

.w-\[480px\] {
  width: 480px !important
}

.w-\[5px\] {
  width: 5px !important
}

.w-\[70px\] {
  width: 70px !important
}

.w-\[778px\] {
  width: 778px !important
}

.w-\[80px\] {
  width: 80px !important
}

.w-\[84px\] {
  width: 84px !important
}

.w-\[850px\] {
  width: 850px !important
}

.w-full {
  width: 100% !important
}

.min-w-0 {
  min-width: 0px !important
}

.min-w-\[112px\] {
  min-width: 112px !important
}

.min-w-\[170px\] {
  min-width: 170px !important
}

.min-w-\[180px\] {
  min-width: 180px !important
}

.min-w-\[300px\] {
  min-width: 300px !important
}

.min-w-\[36px\] {
  min-width: 36px !important
}

.max-w-\[140px\] {
  max-width: 140px !important
}

.max-w-\[91\.78px\] {
  max-width: 91.78px !important
}

.flex-1 {
  flex: 1 1 0% !important
}

.flex-auto {
  flex: 1 1 auto !important
}

.shrink-0 {
  flex-shrink: 0 !important
}

.basis-1\/4 {
  flex-basis: 25% !important
}

.basis-\[180px\] {
  flex-basis: 180px !important
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important
}

.cursor-pointer {
  cursor: pointer !important
}

.resize {
  resize: both !important
}

.flex-col {
  flex-direction: column !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.items-center {
  align-items: center !important
}

.justify-end {
  justify-content: flex-end !important
}

.justify-center {
  justify-content: center !important
}

.justify-between {
  justify-content: space-between !important
}

.justify-around {
  justify-content: space-around !important
}

.gap-\[10px\] {
  gap: 10px !important
}

.gap-\[15px\] {
  gap: 15px !important
}

.gap-\[7px\] {
  gap: 7px !important
}

.gap-x-\[10px\] {
  -moz-column-gap: 10px !important;
       column-gap: 10px !important
}

.gap-x-\[12px\] {
  -moz-column-gap: 12px !important;
       column-gap: 12px !important
}

.gap-x-\[15px\] {
  -moz-column-gap: 15px !important;
       column-gap: 15px !important
}

.gap-x-\[20px\] {
  -moz-column-gap: 20px !important;
       column-gap: 20px !important
}

.gap-x-\[24px\] {
  -moz-column-gap: 24px !important;
       column-gap: 24px !important
}

.gap-x-\[25px\] {
  -moz-column-gap: 25px !important;
       column-gap: 25px !important
}

.gap-x-\[2px\] {
  -moz-column-gap: 2px !important;
       column-gap: 2px !important
}

.gap-x-\[4px\] {
  -moz-column-gap: 4px !important;
       column-gap: 4px !important
}

.gap-x-\[5px\] {
  -moz-column-gap: 5px !important;
       column-gap: 5px !important
}

.gap-x-\[6px\] {
  -moz-column-gap: 6px !important;
       column-gap: 6px !important
}

.gap-x-\[8px\] {
  -moz-column-gap: 8px !important;
       column-gap: 8px !important
}

.gap-y-\[100px\] {
  row-gap: 100px !important
}

.gap-y-\[10px\] {
  row-gap: 10px !important
}

.gap-y-\[14px\] {
  row-gap: 14px !important
}

.gap-y-\[15px\] {
  row-gap: 15px !important
}

.gap-y-\[16px\] {
  row-gap: 16px !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-x-auto {
  overflow-x: auto !important
}

.overflow-y-auto {
  overflow-y: auto !important
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important
}

.overflow-ellipsis {
  text-overflow: ellipsis !important
}

.text-ellipsis {
  text-overflow: ellipsis !important
}

.whitespace-nowrap {
  white-space: nowrap !important
}

.rounded-\[0\] {
  border-radius: 0 !important
}

.rounded-\[10px\] {
  border-radius: 10px !important
}

.rounded-\[2px\] {
  border-radius: 2px !important
}

.rounded-\[3px\] {
  border-radius: 3px !important
}

.rounded-\[50\%\] {
  border-radius: 50% !important
}

.rounded-\[5px\] {
  border-radius: 5px !important
}

.rounded-\[999px\] {
  border-radius: 999px !important
}

.rounded-full {
  border-radius: 9999px !important
}

.rounded-br-\[10px\] {
  border-bottom-right-radius: 10px !important
}

.rounded-tl-\[8px\] {
  border-top-left-radius: 8px !important
}

.rounded-tr-\[8px\] {
  border-top-right-radius: 8px !important
}

.border {
  border-width: 1px !important
}

.border-\[1px\] {
  border-width: 1px !important
}

.border-solid {
  border-style: solid !important
}

.border-\[\#D0D3D6\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(208 211 214 / var(--tw-border-opacity)) !important
}

.border-\[\#d9d9d9\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(217 217 217 / var(--tw-border-opacity)) !important
}

.bg-\[\#0173FC\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(1 115 252 / var(--tw-bg-opacity)) !important
}

.bg-\[\#0a2f5b05\] {
  background-color: #0a2f5b05 !important
}

.bg-\[\#154c8e08\] {
  background-color: #154c8e08 !important
}

.bg-\[\#5e62670d\] {
  background-color: #5e62670d !important
}

.bg-\[\#EFA20B\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 162 11 / var(--tw-bg-opacity)) !important
}

.bg-\[\#F45F3E\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 95 62 / var(--tw-bg-opacity)) !important
}

.bg-\[\#efa20b26\] {
  background-color: #efa20b26 !important
}

.bg-\[\#f2f5f8\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(242 245 248 / var(--tw-bg-opacity)) !important
}

.bg-\[\#f45f3e26\] {
  background-color: #f45f3e26 !important
}

.bg-\[\#fff\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.bg-\[\#ffffff12\] {
  background-color: #ffffff12 !important
}

.bg-\[\#ffffff33\] {
  background-color: #ffffff33 !important
}

.bg-\[\#ffffff\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.bg-\[var\(--ant-color-primary\)\] {
  background-color: var(--ant-color-primary) !important
}

.bg-\[var\(--ant-color-split\)\] {
  background-color: var(--ant-color-split) !important
}

.bg-transparent {
  background-color: transparent !important
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important
}

.bg-\[length\:100\%_100\%\] {
  background-size: 100% 100% !important
}

.bg-cover {
  background-size: cover !important
}

.bg-center {
  background-position: center !important
}

.bg-no-repeat {
  background-repeat: no-repeat !important
}

.p-\[10px\] {
  padding: 10px !important
}

.p-\[12px\] {
  padding: 12px !important
}

.p-\[15px\] {
  padding: 15px !important
}

.p-\[20px\] {
  padding: 20px !important
}

.p-\[8px\] {
  padding: 8px !important
}

.px-\[12px\] {
  padding-left: 12px !important;
  padding-right: 12px !important
}

.px-\[15px\] {
  padding-left: 15px !important;
  padding-right: 15px !important
}

.px-\[20px\] {
  padding-left: 20px !important;
  padding-right: 20px !important
}

.px-\[30px\] {
  padding-left: 30px !important;
  padding-right: 30px !important
}

.px-\[8px\] {
  padding-left: 8px !important;
  padding-right: 8px !important
}

.py-\[10px\] {
  padding-top: 10px !important;
  padding-bottom: 10px !important
}

.py-\[15px\] {
  padding-top: 15px !important;
  padding-bottom: 15px !important
}

.py-\[20px\] {
  padding-top: 20px !important;
  padding-bottom: 20px !important
}

.py-\[5px\] {
  padding-top: 5px !important;
  padding-bottom: 5px !important
}

.py-\[7px\] {
  padding-top: 7px !important;
  padding-bottom: 7px !important
}

.py-\[8\.5px\] {
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important
}

.py-\[80px\] {
  padding-top: 80px !important;
  padding-bottom: 80px !important
}

.pb-\[15px\] {
  padding-bottom: 15px !important
}

.pb-\[20px\] {
  padding-bottom: 20px !important
}

.pb-\[4px\] {
  padding-bottom: 4px !important
}

.pl-\[30px\] {
  padding-left: 30px !important
}

.pl-\[46px\] {
  padding-left: 46px !important
}

.pr-\[16px\] {
  padding-right: 16px !important
}

.pt-\[20px\] {
  padding-top: 20px !important
}

.pt-\[25px\] {
  padding-top: 25px !important
}

.pt-\[30px\] {
  padding-top: 30px !important
}

.text-center {
  text-align: center !important
}

.text-right {
  text-align: right !important
}

.align-middle {
  vertical-align: middle !important
}

.align-\[3px\] {
  vertical-align: 3px !important
}

.text-\[12px\] {
  font-size: 12px !important
}

.text-\[14px\] {
  font-size: 14px !important
}

.text-\[16px\] {
  font-size: 16px !important
}

.text-\[17px\] {
  font-size: 17px !important
}

.text-\[18px\] {
  font-size: 18px !important
}

.text-\[20px\] {
  font-size: 20px !important
}

.text-\[22px\] {
  font-size: 22px !important
}

.text-\[24px\] {
  font-size: 24px !important
}

.text-\[26px\] {
  font-size: 26px !important
}

.text-\[30px\] {
  font-size: 30px !important
}

.text-\[34px\] {
  font-size: 34px !important
}

.text-\[36px\] {
  font-size: 36px !important
}

.text-\[40px\] {
  font-size: 40px !important
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important
}

.font-bold {
  font-weight: 700 !important
}

.font-medium {
  font-weight: 500 !important
}

.font-normal {
  font-weight: 400 !important
}

.italic {
  font-style: italic !important
}

.leading-\[18px\] {
  line-height: 18px !important
}

.leading-\[21px\] {
  line-height: 21px !important
}

.leading-\[24px\] {
  line-height: 24px !important
}

.leading-\[35px\] {
  line-height: 35px !important
}

.leading-\[36px\] {
  line-height: 36px !important
}

.leading-\[38px\] {
  line-height: 38px !important
}

.leading-\[39px\] {
  line-height: 39px !important
}

.text-\[\#0173FC\] {
  --tw-text-opacity: 1 !important;
  color: rgb(1 115 252 / var(--tw-text-opacity)) !important
}

.text-\[\#2F343A\] {
  --tw-text-opacity: 1 !important;
  color: rgb(47 52 58 / var(--tw-text-opacity)) !important
}

.text-\[\#5C6269\] {
  --tw-text-opacity: 1 !important;
  color: rgb(92 98 105 / var(--tw-text-opacity)) !important
}

.text-\[\#88919C\] {
  --tw-text-opacity: 1 !important;
  color: rgb(136 145 156 / var(--tw-text-opacity)) !important
}

.text-\[\#8D969F\] {
  --tw-text-opacity: 1 !important;
  color: rgb(141 150 159 / var(--tw-text-opacity)) !important
}

.text-\[\#8d969f\] {
  --tw-text-opacity: 1 !important;
  color: rgb(141 150 159 / var(--tw-text-opacity)) !important
}

.text-\[\#9CA6B1\] {
  --tw-text-opacity: 1 !important;
  color: rgb(156 166 177 / var(--tw-text-opacity)) !important
}

.text-\[\#B9BBBE\] {
  --tw-text-opacity: 1 !important;
  color: rgb(185 187 190 / var(--tw-text-opacity)) !important
}

.text-\[\#EFA20B\] {
  --tw-text-opacity: 1 !important;
  color: rgb(239 162 11 / var(--tw-text-opacity)) !important
}

.text-\[\#F45F3E\] {
  --tw-text-opacity: 1 !important;
  color: rgb(244 95 62 / var(--tw-text-opacity)) !important
}

.text-\[\#f93a3a\] {
  --tw-text-opacity: 1 !important;
  color: rgb(249 58 58 / var(--tw-text-opacity)) !important
}

.text-\[\#ffffff\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.text-\[\#ffffffcc\] {
  color: #ffffffcc !important
}

.text-\[\#ffffffe6\] {
  color: #ffffffe6 !important
}

.text-\[unset\] {
  color: unset !important
}

.text-\[var\(--ant-color-error\)\] {
  color: var(--ant-color-error) !important
}

.text-\[var\(--ant-color-primary\)\] {
  color: var(--ant-color-primary) !important
}

.text-\[var\(--ant-color-primary-text\)\] {
  color: var(--ant-color-primary-text) !important
}

.text-\[var\(--ant-color-success\)\] {
  color: var(--ant-color-success) !important
}

.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.underline {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important
}

.opacity-100 {
  opacity: 1 !important
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.hover\:bg-\[\#ffffff26\]:hover {
  background-color: #ffffff26 !important
}

.hover\:bg-\[var\(--ant-color-primary\)\]:hover {
  background-color: var(--ant-color-primary) !important
}

.hover\:text-\[\#ffffff\]:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important
}

.hover\:text-\[var\(--ant-color-primary\)\]:hover {
  color: var(--ant-color-primary) !important
}

@media (min-width: 768px) {
  .md\:flex-col {
    flex-direction: column !important
  }
}
